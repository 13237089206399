.grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
}
.cont-item{
    overflow: overlay;
    height: 600px;

}
.item-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 60px;
  height: 60px;
  margin: 30px;
  color: #7787b8 ;
  cursor   : pointer;

}
