.Modal {
    top             : 50%;
    left            : 50%;
    right           : auto;
    bottom          : auto;
    transform       : translate(-50%, -50%);
    position        : absolute;
    display         : flex;
    justify-content : center;
    align-items     : center;
    text-align      : center;
}

.Overlay {
    position        : fixed;
    top             : 0;
    left            : 0;
    right           : 0;
    bottom          : 0;
    background-color: rgba(22, 22, 22, 0.693);
    z-index         : 2;

}

.btn-modal-close {
    position : absolute;
    right    : 0;
    top      : 0;
    font-size: 30px;
    cursor   : pointer;
    display  : flex;
}